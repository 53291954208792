import React from "react"
import { Link } from 'react-router-dom';
import { accountClient, authClient } from '../../../clients/Registry';
import { followRedirect } from '../login';

export class LoginForm extends React.Component {
    state = {
        loading: false,
        error: "",
        username: "",
        password: ""
    }
    handleSubmit(event) {
        event.preventDefault();
        this.setState({ loading: true, error: "" });
        accountClient.login(
            this.state.username,
            this.state.password,
            this.callback.bind(this)
        )
    }    
    callback = async (status, resp) => {
        this.setState({loading: false});
        if (resp === undefined || resp === null) {
            this.setState({error: "Error: could not complete login"});
        } else if ("error" in resp) {
            this.setState({error: resp['error']});
        } else if (status !== 200) {
            this.setState({error: "Error: could not complete login"});
        } else {
            authClient.setToken(resp['token']);
            followRedirect();
        }
    }
    render() {
        return <div className="Login">
            <br /><br />
            <form onSubmit={this.handleSubmit.bind(this)}>
                <span className="header">Sign In</span><br />
                <span className="error">{this.state.error}</span><br />
                <input type="hidden" name="form_name" id="form_name" value="login" />
                <input type="text" id="user" name="user" placeholder="Username" 
                    value={this.state.username}
                    onChange={event => this.setState({ username: event.target.value })} 
                    autoFocus  />
                <input type="password" id="pass" name="pass" placeholder="Password" 
                    value={this.state.password}
                    onChange={event => this.setState({ password: event.target.value })} />
                <input type="submit" value={ this.state.loading ? 'Loading....' : 'Sign In' } id="loginButton" />

                <p className="footer">Don't have an account?&nbsp;
                    <Link to={`register${window.location.search}`} className="strong link">Sign Up</Link>
                </p><br />
                <Link to={`forgot${window.location.search}`} className="strong forgot link">Forgot Password</Link>
            </form>
        </div>
    }
}