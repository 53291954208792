import React from "react"
import { Link } from 'react-router-dom';
import { accountClient, authClient } from '../../../clients/Registry';
import { followRedirect } from '../login';

export class RegistrationForm extends React.Component {
    state = {
        loading: false,
        error: "",
        first: "",
        last: "",
        username: "",
        password: ""
    }
    handleSubmit(event) {
        event.preventDefault();
        this.setState({ loading: true, error: "" });
        accountClient.register(
            this.state.first,
            this.state.last,
            this.state.username,
            this.state.password,
            this.callback.bind(this)
        )
    }    
    callback = async (status, resp) => {
        this.setState({loading: false});
        if (resp === undefined) {
            this.setState({error: "Error: could not complete login"});
        } else if ("error" in resp) {
            this.setState({error: resp['error']});
        } else if (status !== 200) {
            this.setState({error: "Error: could not complete login"});
        } else {
            authClient.setToken(resp['token']);
            followRedirect();
        }
    }
    render() {
        return <div className="Registration">
            <br /><br />
            <form onSubmit={this.handleSubmit.bind(this)}>
                <span className="header">Registration</span><br />
                <span className="error">{this.state.error}</span><br />
                <input type="hidden" name="form_name" id="form_name" value="registration" />
                <input type="text" id="first" name="first" placeholder="First" 
                    value={this.state.first}
                    onChange={event => this.setState({ first: event.target.value })}
                    autoFocus />
                <input type="text" id="last" name="last" placeholder="Last" 
                    value={this.state.last}
                    onChange={event => this.setState({ last: event.target.value })}/>
                <br /><br /><br />
                <input type="text" id="user" name="user" placeholder="Username" 
                    value={this.state.username}
                    onChange={event => this.setState({ username: event.target.value })} 
                    autoFocus  />
                <input type="password" id="pass" name="pass" placeholder="Password" 
                    value={this.state.password}
                    onChange={event => this.setState({ password: event.target.value })} />

                <input type="submit" value={ this.state.loading ? 'Loading....' : 'Register' } id="registerButton" />

                <p className="footer">
                    Already have an account?&nbsp;
                    <Link to={`login${window.location.search}`} className="strong link">Sign In</Link>
                </p>
            </form>
        </div>
    }
}