import { useHistory } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { authClient } from "../../../clients/Registry";

export function LogoutForm() {

    const history = useHistory();
    const goTo = (url) => history.push(url);

    authClient.logout(() => goTo("/"));

    return <CircularProgress color="inherit" />;

}