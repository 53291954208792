import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import HomeIcon from '@mui/icons-material/Home';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import GroupIcon from '@mui/icons-material/Group';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import Paper from '@mui/material/Paper';

export function MobileNav() {
    const [value, setValue] = useState(getPathID());
    
    const history = useHistory();
    const goTo = (url) => history.push(url);

    useEffect(() => {
        return history.listen((location) => {
            setValue(getPathID())
        })
    },[history])

    return <Paper sx={{ position: 'fixed', bottom: 0, left: 1, right: 1 }} elevation={3}>
        <BottomNavigation
            showLabels
            value={value}
            onChange={(event, newValue) => {
                setValue(newValue); //or change current view
            }}
        >
            <BottomNavigationAction label="Home" icon={<HomeIcon />}
                onClick={() => goTo('/') } />
            <BottomNavigationAction label="Leauges" icon={<EmojiEventsIcon />} 
                onClick={() => goTo('/league') } />
            <BottomNavigationAction label="Friends" icon={<GroupIcon />} 
                onClick={() => goTo('/friends') } />
            <BottomNavigationAction label="Stats" icon={<LeaderboardIcon />} 
                onClick={() => goTo('/stats') } />
        </BottomNavigation>
    </Paper>;

}

function getPathID() {
    const base = window.location.pathname.substring(1).split("/")[0];
    switch (base) {
        case "":
            return 0;
        case "league":
            return 1;
        case "friends":
            return 2;
        case "stats":
            return 3;
        default:
            return null;
    }
}