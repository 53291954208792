import React, { useEffect, useState } from 'react';
import Alert from '@mui/material/Alert';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import CircularProgress from '@mui/material/CircularProgress';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { PendingReqList } from './PendingReqList';
import { friendsClient } from '../../clients/Registry';

export function FriendList() {

    const [ friends, setFriends ] = useState([]);
    const [ pending, setPending ] = useState([]);
    const [ loading, setLoading ] = useState(true);

    // load friends and pending on init
    useEffect(() => {
        if (!loading) return;
        friendsClient.getfriends((status, resp) => {
            setLoading(false);
            if (status === 200) {
                if (resp && resp.friends?.length > 0) {
                    setFriends(resp.friends);
                }
                if (resp && resp.pending?.length > 0) {
                    setPending(resp.pending);
                }
            } else {
                // could not load
            }
        })
    }, [friends, pending, loading]);

    
    const [ friendListRender, setFriendListRender ] = useState([]);

    useEffect(() => {
        let acc = [];
        for (let i = 0; i < friends.length; i++) {
            let f = friends[i];
            acc.push(
                <FriendItem name={f.name} username={f.username} key={i} />
            );
            acc.push(<Divider component="li" key={i + "_div"}/>)
        }
        setFriendListRender(acc);
    }, [friends]);
    
    if (loading) {
        return <center>
            <br />
            <CircularProgress color="inherit" />
        </center>
    }
    return <div>
        <PendingReqList invites={pending} />
        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
            {friendListRender}
        </List>
    </div>;

}

function FriendItem(props) {

    const [ anchorEl, setAnchorEl ] = useState(null);
    const [ removed, setRemoved ] = useState(false);
    const [ error, setError ] = useState("");
    const open = Boolean(anchorEl);

    const removeFriend = (username) => {
        friendsClient.removeFriend(username, removeFriendCallback);
    }
    const removeFriendCallback = (status, resp) => {
        if (resp === undefined || resp === null || status !== 200) {
            setError("Could not remove user from friends");
        } else {
            setRemoved(true);
        }
    }

    if (removed) {
        return <Alert severity="success" sx={{ marginBottom: '5px'}}>User removed from friends</Alert>
    }
    return <div>
        { error ? <Alert severity="error" sx={{ marginTop: '5px'}}>{error}</Alert> : null }
        <ListItem 
            alignItems="flex-start" width="100%"
            secondaryAction={
                <IconButton edge="end" aria-label="delete"
                    onClick={(event) => setAnchorEl(event.currentTarget)}>
                    <MoreVertIcon />
                </IconButton>
            }
        >
            <ListItemAvatar key={props.username + "_avatar"}>
                <Avatar alt={props.name} src={props.name[0]} />
            </ListItemAvatar>
            <ListItemText
                primary={props.name}
                secondary={props.username}
            />
        </ListItem>
        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={() => setAnchorEl(null)}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
        >
            <MenuItem key={1} onClick={() => removeFriend(props.username)}>Remove</MenuItem>
        </Menu>
    </div>;
}