import * as dotenv from 'dotenv';
import { ClientBase } from './base/ClientBase';
import { TokenContainer } from './base/TokenContainer';

import { AccountClient } from './AccountClient';
import { AuthClient } from './AuthClient';
import { FriendsClient } from './FriendsClient';
import { GameClient } from './GameClient';

dotenv.config(); 
export const API = process.env.REACT_APP_API;

const tokenContainer = new TokenContainer();
const base = new ClientBase(
    API,
    tokenContainer
);

export const accountClient = new AccountClient(base);
export const authClient = new AuthClient(API, base, tokenContainer);
export const friendsClient = new FriendsClient(base);
export const gamesClient = new GameClient(base);