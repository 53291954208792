import React from "react";
import { Link } from "react-router-dom";

export class LandingPage extends React.Component {
    render() {
        return <div className="HomePage">
            <p>"Hello, welcome to stopwatch baseball"</p>
            <Link to="/login">Sign In</Link>
        </div>;
    }
}