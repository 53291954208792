import { useState } from 'react';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { friendsClient } from '../../clients/Registry';

export function AddFriendForm() {

    const [ username, setUsername ] = useState("");
    const [ loading, setLoading ] = useState(false);
    const [ error, setError ] = useState("");
    const [ success, setSuccess ] = useState("");

    const addFriend = (event) => {
        event.preventDefault();
        setLoading(true);
        setError("");
        setSuccess("");
        friendsClient.addFriend(
            username,
            addFriendCallback
        )
    }
    const addFriendCallback = (status, resp) => {
        setLoading(false);
        if (resp === undefined || resp === null) {
            setError("Could not add friend");
        } else if (status === 400) {
            if (resp.message === "INVALID_USERNAME") {
                setError("Username could not be found")
            } else {
                setError("Could not add friend");
            }
        } else if (status !== 200) {
            setError("Could not add friend");
        } else {
            setSuccess("Successfully sent friend request to " + username);
            setUsername("");
        }
    }

    return <form onSubmit={addFriend}>
        { error.length > 0 ? 
            <Alert severity="error" sx={{ marginBottom: '5px'}}>{error}</Alert> : 
            null 
        }
        { success.length > 0 ? 
            <Alert severity="success" sx={{ marginBottom: '5px'}}>{success}</Alert> : 
            null 
        }
        <Grid container spacing={{ xs: 1, md: 0 }} columns={{ xs: 12, sm: 12, md: 12 }}>
            <Grid item xs={9}>
                <TextField label="Add Friend" variant="outlined" 
                    size="small" sx={{width: '100%' }}
                    value={username} onChange={(event) => setUsername(event.target.value)} 
                />
            </Grid>
            <Grid item xs={3}>
            <Button variant="contained" type="submit">
                { loading ? '...' : 'Add' }
            </Button>
            </Grid>
        </Grid>
    </form>
}