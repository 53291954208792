export class GameClient {

    constructor(
        clientBase
    ) {
        this.client = clientBase;
    }

    createGame(friendId, callback) {
        this.client.sendRequest(
            "POST",
            "/games/create",
            {
                "friendId": friendId
            },
            callback
        )
    }

    listGames(callback) {
        this.client.sendRequest(
            "GET",
            "/games/all",
            {},
            callback
        )
    }

    getGame(gameId, callback) {
        this.client.sendRequest(
            "GET",
            "/games/id/"+ gameId,
            {},
            callback
        )
    }

    bulkUpdate(gameId, history, callback) {
        this.client.sendRequest(
            "POST",
            "/games/id/"+ gameId,
            { "history": history },
            callback
        )
    }
}