import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { LoginPage } from './pages/login/LoginPage';
import { LandingPage } from './pages/landing/LandingPage';
import { Dashboard } from './pages/dashboard/Dashboard';
import { authClient } from './clients/Registry';


function App() {
  const loggedIn = authClient.hasCredentials();
  const redirectPath = authClient.getRedirect();
  return (
    <Switch>
      <Route path="/" component={loggedIn ? Dashboard : LandingPage}  exact />
      <Route path="/login" component={LoginPage}  exact />
      <Route path="/register" component={LoginPage}  exact />
      <Route path="/forgot" component={LoginPage}  exact />
      <Route path="/logout" component={LoginPage}  exact />
      <Route path="/" >
        {loggedIn ? <Dashboard /> : <Redirect to={redirectPath} /> }   
      </Route>
    </Switch>
  );
}
export default App;