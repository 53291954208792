import React from "react";
import Typography from '@mui/material/Typography';
import { AddFriendForm } from './AddFriendForm';
import { FriendList } from './FriendList';

import './FriendsPage.scss';

export class FriendsPage extends React.Component {
    render() {
        return <div className="FriendsPage">
            <Typography variant="h5" component="h5" >
                My Friends
            </Typography>
            <br />
            <AddFriendForm />
            <FriendList />
        </div>;
    }
}