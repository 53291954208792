import React, { useState } from 'react';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { friendsClient } from '../../clients/Registry';

export function PendingReqList(props) {
    const arr = props.invites ?? [];
    const acc = [];
    for (let i = 0; i < arr.length; i++) {
        acc.push(
            <PendingInvite username={arr[i].username} name={arr[i].name} />
        );
    }
    if (!props.invites || props.invites.length === 0) {
        return null;
    }
    return <Accordion sx={{ marginTop: 2, border: 1 }}>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
        >
            <Typography>Pending Invites</Typography>
        </AccordionSummary>
        <AccordionDetails>
            {acc}
        </AccordionDetails>
    </Accordion>;
}
function PendingInvite(props) {

    const [ loading, setLoading ] = useState(false);
    const [ error, setError ] = useState("");
    const [ success, setSuccess ] = useState("");

    const acceptInvite = () => {
        setLoading(true);
        setError("");
        setSuccess("");
        friendsClient.addFriend(
            props.username,
            addFriendCallback
        )
    }
    const addFriendCallback = (status, resp) => {
        setLoading(false);
        if (resp === undefined || resp === null) {
            setError("Could not add friend");
        } else if (status === 400) {
            if (resp.message === "INVALID_USERNAME") {
                setError("Username could not be found")
            } else {
                setError("Could not add friend");
            }
        } else if (status !== 200) {
            setError("Could not add friend");
        } else {
            setSuccess("Added " + props.username);
        }
    }

    const rejectInvite = () => {
        setLoading(true);
        setError("");
        setSuccess("");
        friendsClient.removeFriend(
            props.username,
            rejectInviteCallback
        )
    }
    const rejectInviteCallback = (status, resp) => {
        setLoading(false);
        if (resp === undefined || resp === null) {
            setError("Error rejecting invitation");
        } else if (status === 400) {
            if (resp.message === "INVALID_USERNAME") {
                setError("Username could not be found")
            } else {
                setError("Error rejecting invitation");
            }
        } else if (status !== 200) {
            setError("Error rejecting invitation");
        } else {
            setSuccess("Successfully remove invite");
        }
    }

    return <div>
        { error.length > 0 ? 
            <Alert severity="error" sx={{ marginBottom: '5px'}}>{error}</Alert> : 
            null 
        }
        { success.length > 0 ? 
            <Alert severity="success" sx={{ marginBottom: '5px'}}>{success}</Alert> : 
            <Grid container spacing={{ xs: 1, md: 0 }} columns={{ xs: 10, sm: 10, md: 10 }}>
                <Grid item xs={8}>
                    <big><b>{props.name}</b></big>
                    <br />
                    {props.username}
                </Grid>
                <Grid item xs={1}>
                    { loading ? null : 
                        <ClearIcon sx={{cursor: 'pointer'}} onClick={() => rejectInvite()} /> }
                </Grid>
                <Grid item xs={1}>
                    { loading ? '...' : 
                        <CheckIcon sx={{cursor: 'pointer'}} onClick={() => acceptInvite()} /> }
                </Grid>
            </Grid>
        }
    </div>
}