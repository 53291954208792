import React from "react";
import { useHistory } from 'react-router-dom';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import GroupIcon from '@mui/icons-material/Group';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import List from '@mui/material/List';
import Drawer from '@mui/material/Drawer';

export function SidebarNav(props) {

    const history = useHistory();
    const goTo = (url) => history.push(url);

    return <Drawer
        variant="permanent"
        sx={{
            width:  props.navWidth,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: { width: props.navWidth, boxSizing: 'border-box' },
        }}
    >
        <Toolbar />
        <Box sx={{ overflow: 'auto' }}>
            <List>
                <ListItem button key="1"
                    onClick={() => goTo('/') } >
                    <ListItemIcon><HomeIcon /></ListItemIcon>
                    <ListItemText primary="Dashboard" />
                </ListItem>
                <ListItem button key="2"
                    onClick={() => goTo('/league') }>
                    <ListItemIcon><EmojiEventsIcon /></ListItemIcon>
                    <ListItemText primary="Leagues" />
                </ListItem>
                <ListItem button key="3"
                    onClick={() => goTo('/friends') }>
                    <ListItemIcon><GroupIcon /></ListItemIcon>
                    <ListItemText primary="Friends" />
                </ListItem>
                <ListItem button key="4"
                    onClick={() => goTo('/stats') }>
                    <ListItemIcon><LeaderboardIcon /></ListItemIcon>
                    <ListItemText primary="Stats" />
                </ListItem>
            </List>
        </Box>
    </Drawer>

}