import React from "react";
import { Route, Switch } from 'react-router-dom';   
import Box from '@mui/material/Box';
import { LoginForm } from './components/LoginForm';
import { RegistrationForm } from './components/RegistrationForm';
import { ForgotForm } from './components/ForgotForm';
import { LogoutForm } from './components/LogoutForm';

import './LoginPage.scss';

export class LoginPage extends React.Component {

    render() {
        return <div className="LoginPage">
            <center>
                <Box className="LoginPageMain">
                    <img src="/logo512.png" id="LoginLogo" alt="logo" /><br />
                    <Switch>
                        <Route path="/login" component={LoginForm}  exact />
                        <Route path="/register" component={RegistrationForm}  exact />
                        <Route path="/forgot" component={ForgotForm}  exact />
                        <Route path="/logout" component={LogoutForm}  exact />
                    </Switch>
                </Box>
            </center>
        </div>;   
    }
}
export default LoginPage;