export class AccountClient {

    constructor(
        clientBase
    ) {
        this.client = clientBase;
    }

    login(username, password, callback) {
        this.client.sendRequest(
            "POST",
            "/user/login",
            {
                "username": username,
                "password": password,
                "httpOnly": false
            },
            callback
        )
    }

    register(first, last, username, password, callback) {
        this.client.sendRequest(
            "POST",
            "/user",
            {
                "username": username,
                "password": password,
                "first": first,
                "last": last
            },
            callback
        );
    }

    getId(callback) {
        this.client.sendRequest(
            "GET",
            "/user",
            {},
            callback
        );
    }

}