import Cookies from 'js-cookie';

export class AuthClient {
    
    constructor(
        api,
        clientBase,
        tokenContainer
    ) {
        this.API = api;
        this.client = clientBase;
        this.tokenContainer = tokenContainer;
    }

    logout(callback) {
        this.tokenContainer.clearToken()
        this.client.sendRequest(
            "POST",
            "/user/logout",
            {},
            callback
        )
    }

    authPing() {
        const token = Cookies.get("selfset-token") ?? Cookies.get("token");
        if (token == null || token === "") {
            return false;
        }
        var xhr = new XMLHttpRequest();
        xhr.open("GET", `${this.API}/user`, false);
        xhr.withCredentials = true;
        xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
        xhr.setRequestHeader("Authorization", `Bearer ${token}`);
        xhr.send();
        return xhr.status === 200;
    }
    
    isLoggedIn() {
        return this.authPing();
    }

    setToken(token) {
        return this.tokenContainer.setToken(token);
    }

    hasCredentials() {
        return this.tokenContainer.hasToken();
    }

    getRedirect() {
        return this.client.getRedirect();
    }

}
