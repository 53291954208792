import React from "react"
import { Link } from 'react-router-dom';

export class ForgotForm extends React.Component {
    state = {}
    render() {
        return <div className="Forgot">
            <br /><br />
            <form onSubmit={this.handleSubmit}>
                <span className="header">Forgot Password</span><br />
                <span className="error">{this.state.error}</span><br />
                <input type="hidden" name="form_name" id="form_name" value="forgot" />
                <input type="tel" id="user" name="user" placeholder="Username" 
                    value={this.state.username}
                    onChange={event => this.setState({ username: event.target.value })} 
                    autoFocus  />
                <input type="submit" value="Reset Password" id="forgotButton" />


                <p className="footer">Don't have an account?&nbsp;
                    <Link to={`register${window.location.search}`} className="strong link">Sign Up</Link>
                </p><br />
                <p className="footer">
                    <Link to={`login${window.location.search}`} className="strong link">Back to login</Link>
                </p>
            </form>
        </div>
    }
}