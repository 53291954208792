import React from "react";
import { Route, Switch } from 'react-router-dom';
import Box from '@mui/material/Box';
import { BrowserView, MobileView } from 'react-device-detect';
import Toolbar from '@mui/material/Toolbar';
import { DesktopHeader } from './DesktopHeader';
import { SidebarNav } from './SidebarNav';
import { MobileNav } from './MobileNav';

import { HomePage } from '../home/HomePage';
import { LeaguePage } from '../league/LeaguePage';
import { FriendsPage } from '../friends/FriendsPage';
import { StatsPage } from '../stats/StatsPage';
import { SettingsPage } from '../settings/SettingsPage';

import './Dashboard.scss';

const drawerWidth = 240;


export class Dashboard extends React.Component {

    render() {
        return <Box sx={{ display: 'flex' }}>
            <DesktopHeader navWidth={drawerWidth} goTo={this.goTo}/>
            <BrowserView>
                <SidebarNav navWidth={drawerWidth} goTo={this.goTo}/>
            </BrowserView>
            <MobileView>
                <MobileNav />
            </MobileView>
            <Box sx={{ flexGrow: 1, p: 2 }}>
                <Toolbar />
                    <Switch>
                        <Route path="/" component={HomePage}  exact />
                        <Route path="/league" component={LeaguePage}  />
                        <Route path="/friends" component={FriendsPage}  />
                        <Route path="/stats" component={StatsPage}  />
                        <Route path="/settings" component={SettingsPage}  />
                    </Switch>
            </Box>
        </Box>
    }
}