export class FriendsClient {

    constructor(
        clientBase
    ) {
        this.client = clientBase;
    }

    getfriends(callback) {
        this.client.sendRequest(
            "GET",
            "/friends",
            {},
            callback
        )
    }

    addFriend(username, callback) {
        this.client.sendRequest(
            "POST",
            "/friends/add",
            {
                "friendUsername": username
            },
            callback
        )
    }

    removeFriend(username, callback) {
        this.client.sendRequest(
            "POST",
            "/friends/remove",
            {
                "friendUsername": username
            },
            callback
        )
    }

}