import Cookies from 'js-cookie';

export class TokenContainer {

    TOKEN = "selfset-token";
    BACKUP_TOKEN = "token";

    hasToken() {
        return null != this.getToken();
    }

    getToken() {
        return Cookies.get(this.TOKEN) ?? Cookies.get(this.BACKUP_TOKEN);
    }

    setToken(token) {
        Cookies.set(this.TOKEN, token, { expires: 365 });
    }

    clearToken() {
        Cookies.remove(this.TOKEN);
        Cookies.remove(this.BACKUP_TOKEN);
    }

}